<template>
    <div class="container px-1 py-5">
        <div class="flex flex-col items-end justify-between mb-10 md:flex-row md:items-center">
            <div class="flex flex-col mb-5 md:mb-0 md:flex-row md:items-center w-full">
                <h1 class="lg:text-3xl mb-5 md:mb-0 mr-10 text-2xl">{{ __('Inventory') }}</h1>

                <c-control-text
                    class="grow-0 mb-5 md:w-[300px] md:mb-0 md:mr-10"
                    icon="search"
                    name="search"
                    v-bind:placeholder="__('Search by name or article number')"
                    v-model="s"
                    v-on:keyup="search"
                />
            </div>

            <div class="flex items-center shrink-0">
                <c-button class="bg-highlight rounded-br-none rounded-tr-none shrink-0 text-white"
                          router
                          v-bind:to="{ name : 'inventory.create' }"
                          v-if="can('inventory.store')"
                >
                    + {{ __('Add product') }}
                </c-button>
                <c-button class="bg-highlight rounded-br-none rounded-tr-none shrink-0 text-white"
                          router
                          v-bind:to="{ name : 'inventory.create' }"
                          v-if="!can('inventory.store') && can('inventory.import')"
                >
                    + {{ __('Import from XLS') }}
                </c-button>
                <div class="after:absolute after:border-[5px] after:border-transparent after:border-t-white after:content-[''] after:h-0 after:mt-1 after:left-1/2 after:pointer-events-none after:top-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:w-0 px-4 bg-highlight border-l border-indigo-800 cursor-pointer h-9 hover:bg-indigo-700 py-2.5 relative rounded-br rounded-tr"
                     v-if="can('inventory.store|inventory.import')"
                     v-on:click="showAddMenu = !showAddMenu"
                     v-on-click-outside="closeAddMenu"
                >
                    <nav class="absolute bg-white border border-slate-200 flex flex-col min-w-[150px] mt-2 right-0 rounded shadow-sm text-sm top-full whitespace-nowrap z-10"
                         v-show="showAddMenu"
                    >
                        <router-link class="flex border-b border-slate-200 hover:bg-slate-50 items-center p-2"
                                     v-bind:to="{ name : 'inventory.create' }"
                                     v-if="can('inventory.store')"
                        >
                            <c-icon class="h-4 mr-2 stroke-slate-800 w-4" icon="plus" />{{ __('Add product') }}
                        </router-link>
                        <a class="flex hover:bg-slate-50 items-center p-2"
                           v-if="can('inventory.import')"
                           v-on:click="showImportDialog"
                        >
                            <c-icon class="h-4 mr-2 stroke-slate-800 w-4" icon="file-plus" />
                            {{ __('Import from XLS') }}
                        </a>
                        <input class="hidden" id="import-dialog" type="file"
                               v-if="can('inventory.import')"
                               v-on:change="importFile"
                        />
                        <a class="flex hover:bg-slate-50 items-center p-2"
                           v-bind:href="'/templates/' + langStore.locale + '/inventory'"
                        >
                            <c-icon class="h-4 mr-2 stroke-slate-800 w-4" icon="arrow-down" />
                            {{ __('Download XLS template') }}
                        </a>
                    </nav>
                </div>
            </div>
        </div>
    </div>
    <div class="container px-1">
        <c-data-table class="table-fixed" responsive-breakpoint="lg"
                      v-bind:data="tableData"
                      v-if="tableData.items.length > 0"
                      v-on:sort-table="sortTable"
        >
            <template v-slot:item.code="{ item }">
                <span class="cursor-text hover:bg-highlight hover:text-white px-2 py-1 rounded"
                      v-bind:class="{ 'bg-error text-white' : item.values.errors?.code, 'text-error' : item.values.quantity <= 0 }"
                      v-show="!item.values.code_edit"
                      v-on:click="item.values.code_edit = can('inventory.update')"
                >
                    {{ item.values.code }}
                </span>
                <c-control-text class="w-full" name="item.values.code"
                                  v-if="item.values.code_edit" v-model="item.values.code"
                                  v-on:keyup.enter="item.values.code_edit = false; updateItem(item);"
                />
            </template>
            <template v-slot:item.name="{ item }">
                <span class="cursor-text hover:bg-highlight hover:text-white px-2 py-1 rounded"
                      v-bind:class="{ 'bg-error text-white' : item.values.errors?.name, 'text-error' : item.values.quantity <= 0 }"
                      v-show="!item.values.name_edit"
                      v-on:click="item.values.name_edit = can('inventory.update')"
                >
                    {{ item.values.name }}
                </span>
                <c-control-text class="w-full" name="item.values.name"
                                  v-if="item.values.name_edit" v-model="item.values.name"
                                  v-on:keyup.enter="item.values.name_edit = false; updateItem(item);"
                />
            </template>
            <template v-slot:item.quantity="{ item }">
                <span class="cursor-text hover:bg-highlight hover:text-white px-2 py-1 rounded"
                      v-bind:class="{ 'bg-error text-white' : item.values.errors?.quantity, 'text-error' : item.values.quantity <= 0 }"
                      v-show="!item.values.quantity_edit"
                      v-on:click="item.values.quantity_edit = can('inventory.update')"
                >
                    {{ item.values.quantity }}
                </span>
                <c-control-number class="mx-auto w-12" name="item.values.quantity"
                                  v-if="item.values.quantity_edit" v-model="item.values.quantity"
                                  v-on:blur="item.values.quantity_edit = false; updateItem(item);"
                                  v-on:keyup.enter="item.values.quantity_edit = false; updateItem(item);"
                />
            </template>
            <template v-slot:item.um="{ item }">
                <span class="cursor-text hover:bg-highlight hover:text-white px-2 py-1 rounded"
                      v-bind:class="{ 'bg-error text-white' : item.values.errors?.um, 'text-error' : item.values.quantity <= 0 }"
                      v-show="!item.values.um_edit"
                      v-on:click="item.values.um_edit = can('inventory.update')"
                >
                    {{ __('units.' + item.values.um) }}
                </span>
                <c-control-select class="mx-auto w-12" name="item.values.um"
                                  v-bind:options="unitOptions"
                                  v-if="item.values.um_edit" v-model="item.values.um"
                                  v-on:keyup.enter="item.values.um_edit = false; updateItem(item);"
                />
            </template>
            <template v-slot:item.cost_price="{ item }">
                <span class="cursor-text hover:bg-highlight hover:text-white px-2 py-1 rounded"
                      v-bind:class="{ 'bg-error text-white' : item.values.errors?.cost_price, 'text-error' : item.values.quantity <= 0 }"
                      v-show="!item.values.cost_price_edit && sessionStore.user"
                      v-on:click="item.values.cost_price_edit = can('inventory.update')"
                >
                    {{ currencyStore.format(item.values.cost_price, sessionStore.user.tenant.config.currency) }}
                </span>
                <c-control-number class="mx-auto w-20" name="item.values.cost_price"
                                  v-if="item.values.cost_price_edit" v-model="item.values.cost_price"
                                  v-on:keyup.enter="item.values.cost_price_edit = false; updateItem(item);"
                />
            </template>
            <template v-slot:item.sale_price="{ item }">
                <span class="cursor-text hover:bg-highlight hover:text-white px-2 py-1 rounded"
                      v-bind:class="{ 'bg-error text-white' : item.values.errors?.sale_price, 'text-error' : item.values.quantity <= 0 }"
                      v-show="!item.values.sale_price_edit && sessionStore.user"
                      v-on:click="item.values.sale_price_edit = can('inventory.update')"
                >
                    {{ currencyStore.format(item.values.sale_price, sessionStore.user.tenant.config.currency) }}
                </span>
                <c-control-number class="mx-auto w-20" name="item.values.cost_price"
                                  v-if="item.values.sale_price_edit" v-model="item.values.sale_price"
                                  v-on:keyup.enter="item.values.sale_price_edit = false; updateItem(item);"
                />
            </template>
            <template v-slot:item.actions="{ item }">
                <div class="flex items-center justify-center">
                    <c-icon class="cursor-pointer h-5 mr-5 stroke-slate-800 transition-none w-5" icon="star"
                            v-bind:class="{ '!fill-amber-400 !stroke-amber-400' : item.values.is_favourite }"
                            v-if="can('inventory.update')"
                            v-on:click="favourite(item)"
                    />

                    <router-link class="cursor-pointer hover:decoration-dotted hover:underline mr-5 text-highlight text-xs"
                                 v-bind:to="{ name : 'inventory.edit', params : { inventory : item.values.id } }"
                                 v-if="can('inventory.update')"
                    >
                        {{ __('Edit') }}
                    </router-link>

                    <a class="cursor-pointer hover:decoration-dotted hover:underline text-red-500 text-xs"
                       v-if="can('inventory.destroy')"
                       v-on:click="deletedProduct = item.values.id"
                    >
                        {{ __('Delete') }}
                    </a>
                </div>
            </template>
        </c-data-table>

        <c-pagination class="my-10"
                      v-bind:current-page="pagesCurrent"
                      v-bind:pages="pagesTotal"
                      v-if="tableData.items.length > 0"
                      v-on:updatePage="updatePage"
        />

        <div class="flex flex-col items-center justify-center text-center" v-else>
            <img class="mb-5" src="/img/empty-state.png" srcset="/img/empty-state.png 2x"
                 v-bind:alt="__('There are no products available.')"
            />
            <div class="flex flex-col items-center justify-center text-center">
                <span class="font-semibold mb-2 text-center text-lg text-slate-500">
                    {{ __('There are no products available.') }}
                </span>
                <router-link class="text-center text-sm text-highlight"
                             v-bind:to="{ name : 'inventory.create' }"
                             v-if="can('inventory.store')"
                >
                    + {{ __('Add product') }}
                </router-link>
            </div>
        </div>
    </div>

    <c-modal class="md:w-[450px]" v-bind:open="deletedProduct !== null" v-bind:title="__('Delete product')" v-on:closeModal="deletedProduct = null">
        <template v-slot:body>
            <div class="py-5 text-center text-sm">
                {{ __('Are you sure you want to delete this product?') }}
            </div>
        </template>
        <template v-slot:footer>
            <div class="flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" v-on:click="deletedProduct = null">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-error text-white" v-on:click="del">
                    {{ __('Delete') }}
                </c-button>
            </div>
        </template>
    </c-modal>
</template>

<script setup lang="ts">
    import { inject, onMounted, ref } from 'vue';
    import { vOnClickOutside } from '@vueuse/components';
    import debounce from 'debounce';
    import {
        CButton,
        CControlNumber,
        CControlSelect,
        CControlText,
        CDataTable,
        CIcon,
        CModal,
        CPagination,
    } from '@teamfurther/cinderblock';

    import InventoryRepository from '../../repositories/InventoryRepository';
    import { useCurrencyStore } from '../../stores/currency';
    import { useLocalizationStore } from '../../stores/localization';
    import { useSessionStore } from '../../stores/session';

    const __ = inject('__');
    const currencyStore = useCurrencyStore();
    const deletedProduct = ref(null);
    const filters = ref({});
    const inventoryRepository = InventoryRepository.getInstance();
    const langStore = useLocalizationStore();
    const pagesCurrent = ref(1);
    const pagesTotal = ref(1);
    const s = ref<string>('');
    const sessionStore = useSessionStore();

    const search = debounce(async () => {
        filters.value.search  = s.value;

        await getResults();
    }, 500);

    const showAddMenu = ref<boolean>(false);
    const sort = ref<string>('');

    const tableData = ref<object>({
        columnAligns: { quantity: 'center', um: 'center', cost_price: 'center', actions: 'center', sale_price: 'center' },
        columnNames: {
            code: __('Article number'),
            name: __('Name'),
            quantity: __('Quantity'),
            um: __('Unit'),
            cost_price: __('Cost price'),
            sale_price: __('Sale price'),
            actions: __('Actions')
        },
        defaultSort: '+code',
        items: [],
        sortableColumns: ['code', 'name', 'quantity', 'cost_price', 'sale_price'],
    });

    const unitOptions = ref<object[]>([]);

    function closeAddMenu() {
        showAddMenu.value = false;
    }

    async function del() {
        await inventoryRepository.destroy(deletedProduct.value);

        await getResults();

        deletedProduct.value = null;
    }

    async function favourite(item) {
        item.values.is_favourite = !item.values.is_favourite;

        await inventoryRepository.update(item.values.id, item.values).catch((error) => {
            item.values.errors = error.response.data.errors;
        });
    }

    async function getResults() {
        const result = await inventoryRepository.index({
            filters: filters.value,
            page: pagesCurrent.value,
            paginate: 1,
            sort: sort.value,
        });

        pagesCurrent.value = result.current_page;
        pagesTotal.value = result.last_page;

        tableData.value.items = result.data.map((product) => {
            return { values : product }
        });
    }

    async function importFile(e)
    {
        const input: HTMLInputElement = (<HTMLInputElement>e.target);

        if (input && input.files) {
            const data: FormData = new FormData();
            data.append('file', input.files[0]);

            await inventoryRepository.import(data);
        } else {
            console.error('Request: no file found.')
        }
    }

    function showImportDialog() {
        showAddMenu.value = false;

        document.querySelector('#import-dialog').click();
    }

    async function sortTable(sortBy: string) {
        sort.value = sortBy;

        await getResults();
    }

    async function updateItem(item) {
        item.values.errors = null;

        await inventoryRepository.update(item.values.id, item.values).catch((error) => {
            item.values.errors = error.response.data.errors;
        });
    }

    async function updatePage(page: number) {
        pagesCurrent.value = page;

        await getResults();
    }

    onMounted(async () => {
        await getResults();

        unitOptions.value = sessionStore.user.tenant.config.units.split(', ').map(unit => {
            return {
                label: __('units.' + unit),
                value: unit,
            }
        });
    });
</script>
