<template>
    <div class="container px-1 py-5">
        <div class="flex flex-col items-end justify-between lg:flex-row lg:items-center mb-10" dusk="partners-header">
            <div class="flex flex-col justify-between lg:justify-start lg:mb-0 mb-5 md:flex-row md:items-center w-full">
                <h1 class="lg:text-3xl mb-5 md:mb-0 mr-10 text-2xl">{{ __('Partners') }}</h1>

                <c-control-text
                    class="grow md:max-w-[300px]"
                    icon="search"
                    name="search"
                    v-bind:placeholder="__('Search by partner name or tax ID')"
                    v-model="s"
                    v-on:keyup="search"
                />
            </div>

            <div class="flex items-center shrink-0">
                <c-button class="bg-highlight rounded-br-none rounded-tr-none shrink-0 text-white"
                          dusk="create-partner-button"
                          router
                          v-if="can('partners.store')"
                          v-bind:to="{ name : 'partners.create' }"
                >
                    + {{ __('Add partner') }}
                </c-button>
                <c-button class="bg-highlight rounded-br-none rounded-tr-none shrink-0 text-white"
                          dusk="create-partner-button"
                          v-if="!can('partners.store') && can('partners.import')"
                >
                    + {{ __('Import from XLS') }}
                </c-button>
                <div class="after:absolute after:border-[5px] after:border-transparent after:border-t-white after:content-[''] after:h-0 after:mt-1 after:left-1/2 after:pointer-events-none after:top-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:w-0 px-4 bg-highlight border-l border-indigo-800 cursor-pointer h-9 hover:bg-indigo-700 py-2.5 relative rounded-br rounded-tr"
                     v-if="can('partners.import|partners.store')"
                     v-on:click="showAddMenu = !showAddMenu"
                     v-on-click-outside="closeAddMenu"
                >
                    <nav class="absolute bg-white border border-slate-200 flex flex-col min-w-[150px] mt-2 right-0 rounded shadow-sm text-sm top-full whitespace-nowrap z-10"
                         v-show="showAddMenu"
                    >
                        <router-link class="flex border-b border-slate-200 items-center p-2"
                                     v-bind:to="{ name : 'partners.create' }"
                                     v-if="can('partners.store')"
                        >
                            <c-icon class="h-4 mr-2 stroke-slate-800 w-4" icon="plus" />{{ __('Add partner') }}
                        </router-link>
                        <a class="flex hover:bg-slate-50 items-center p-2"
                           v-if="can('partners.import')"
                           v-on:click="showImportDialog"
                        >
                            <c-icon class="h-4 mr-2 stroke-slate-800 w-4" icon="file-plus" />
                            {{ __('Import from XLS') }}
                        </a>
                        <input class="hidden" id="import-dialog" type="file"
                               v-on:change="importFile"
                        />
                        <a class="flex hover:bg-slate-50 items-center p-2"
                           v-if="can('partners.import')"
                           v-bind:href="'/templates/' + langStore.locale + '/partners'"
                        >
                            <c-icon class="h-4 mr-2 stroke-slate-800 w-4" icon="arrow-down" />
                            {{ __('Download XLS template') }}
                        </a>
                    </nav>
                </div>
            </div>
        </div>
        <ul class="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4" dusk="partners-list" v-if="partners.length > 0">
            <li class="bg-white border border-slate-200 group/item flex items-center leading-tight relative rounded shadow-sm"
                v-for="(partner, key) in partners"
            >
                <router-link class="flex h-16 items-center justify-start pl-2 pr-8 py-2 w-full"
                             v-bind:to="{ name : 'partners.edit', params : { partner : partner.id } }"
                             v-if="can('partners.show|partners.update')"
                >
                    <span class="line-clamp-3 text-sm">{{ partner.name }}</span>

                    <span class="absolute cursor-help right-1 top-1"
                          v-if="partner.needs_maintenance"
                          v-on:mouseover="partnerTooltip = partner.id"
                          v-on:mouseout="partnerTooltip = null"
                    >
                        <c-icon class="h-6 stroke-error w-6" icon="alert-triangle" />
                        <c-tooltip position="top" v-bind:active="partnerTooltip === partner.id">
                            {{ __('At least one device needs maintenance.') }}
                        </c-tooltip>
                    </span>
                </router-link>
                <div class="flex h-16 items-center justify-start pl-2 pr-8 py-2 w-full"
                             v-if="!can('partners.show|partners.update')"
                >
                    <span class="line-clamp-3 text-sm">{{ partner.name }}</span>
                </div>

                <div class="absolute -bottom-1 group/card-actions right-1"
                     dusk="kebab-menu"
                     v-if="can('partners.destroy|partners.update')"
                >
                    <span class="cursor-pointer flex h-6 hover:bg-slate-200 items-center justify-center mb-2 rounded w-6"
                          dusk="partner-actions"
                          v-on:click="showCardActions = []; showCardActions[partner.id] = !showCardActions[partner.id]"
                          v-on-click-outside="key === 0 ? closeCardActions : [() => {}]"
                    >
                        <c-icon class="h-5 pointer-events-none stroke-slate-800 w-5" icon="more-vertical" />
                    </span>
                    <div class="absolute bg-slate-700 border border-slate-200 bottom-0 hidden overflow-hidden right-0 rounded shadow-sm translate-y-full z-10"
                         v-bind:class="{ '!block' : showCardActions[partner.id] }"
                    >
                        <router-link class="border-b border-b-slate-500 flex hover:bg-slate-800 items-center p-2 text-white text-xs whitespace-nowrap"
                                     dusk="edit-partner-button"
                                     v-bind:to="{ name : 'partners.edit', params : { partner : partner.id } }"
                                     v-if="can('partners.update')"
                        >
                            <c-icon class="h-4 mr-2 stroke-white w-4" icon="edit-3" />{{ __('Edit') }}
                        </router-link>
                        <a class="cursor-pointer flex hover:bg-slate-800 items-center p-2 text-red-300 text-xs whitespace-nowrap"
                           dusk="delete-partner-button"
                           v-if="can('partners.destroy')"
                           v-on:click="deletedPartner = partner.id"
                        >
                            <c-icon class="h-4 mr-2 stroke-red-300 w-4" icon="trash-2" />{{ __('Delete') }}
                        </a>
                    </div>
                </div>
            </li>
        </ul>
        <div class="flex flex-col items-center justify-center text-center" v-else>
            <img class="mb-5" src="/img/empty-state.png" srcset="/img/empty-state.png 2x"
                 v-bind:alt="__('There are no partners available.')"
            />
            <div class="flex flex-col items-center justify-center text-center">
                <span class="font-semibold mb-2 text-center text-lg text-slate-500">{{ __('There are no partners available.') }}</span>
                <router-link class="text-center text-sm text-highlight"
                             v-bind:to="{ name : 'partners.create' }"
                             v-if="can('partners.store')"
                >
                    + {{ __('Add partner') }}
                </router-link>
            </div>
        </div>
    </div>
    <c-modal class="md:w-[450px]" v-bind:open="deletedPartner !== null" v-bind:title="__('Delete partner')" v-on:closeModal="deletedPartner = null">
        <template v-slot:body>
            <div class="py-5 text-center text-sm">
                {{ __('Are you sure you want to delete this partner?') }}
            </div>
        </template>
        <template v-slot:footer>
            <div class="flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" v-on:click="deletedPartner = null">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-error text-white" dusk="delete-partner-modal-button" v-on:click="del">
                    {{ __('Delete') }}
                </c-button>
            </div>
        </template>
    </c-modal>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import { vOnClickOutside } from '@vueuse/components';
    import debounce from 'debounce';
    import {
        CButton,
        CControlText,
        CIcon,
        CModal,
        CTooltip,
    } from '@teamfurther/cinderblock';

    import PartnerRepository from '../../repositories/PartnerRepository';
    import { useLocalizationStore } from '../../stores/localization';

    const closeCardActions = [
        (e) => {
            if (
                !e.target.parentNode.className?.toString().includes('group/card-actions')
            ) {
                showCardActions.value = [];
            }
        }
    ];

    const deletedPartner = ref(null);

    const langStore = useLocalizationStore();

    const partnerRepository = PartnerRepository.getInstance();
    const partners = ref<Object[]>([]);
    const partnerTooltip = ref<string | null>(null);
    const s = ref<string>('');

    const search = debounce(async () => {
        partners.value = await partnerRepository.index({
            filters: {
                search : s.value,
            },
        });
    }, 500);

    const showAddMenu = ref<boolean>(false);
    const showCardActions = ref<boolean[]>([]);

    function closeAddMenu() {
        showAddMenu.value = false;
    }

    async function del() {
        await partnerRepository.destroy(deletedPartner.value);

        let partnersTemp = partners.value;
        partners.value.forEach((partner, key) => {
            if (partner.id === deletedPartner.value) {
                partnersTemp.splice(key, 1);
            }
        });
        partners.value = partnersTemp;

        deletedPartner.value = null;
    }

    async function importFile(e)
    {
        const input: HTMLInputElement = (<HTMLInputElement>e.target);

        if (input && input.files) {
            const data: FormData = new FormData();
            data.append('file', input.files[0]);

            await partnerRepository.import(data);
        } else {
            console.error('Request: no file found.')
        }
    }

    function showImportDialog() {
        showAddMenu.value = false;

        document.querySelector('#import-dialog').click();
    }

    onMounted(async () => {
        partners.value = await partnerRepository.index();
    });
</script>
