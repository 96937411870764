<template>
    <div class="container flex flex-col h-screen justify-center px-1 py-10">
        <img class="h-6 mb-5 md:mb-8 mx-auto" src="/img/logo.svg" v-bind:alt="app.name" />
        <form
            v-on:submit.prevent="submit"
        >
            <div class="bg-white border border-slate-200 max-w-[400px] mb-10 mx-auto p-5 rounded shadow-sm w-full"
                 v-on:keyup.enter="step = 2"
                 v-show="step === 1"
            >
                <c-control-text name="name" required type="text"
                                v-bind:invalid="!!requestStore.errors?.name"
                                v-bind:label="__('Your name')"
                                v-bind:placeholder="__('placeholders.person_name')"
                                v-model="name"
                />

                <c-control-text class="mt-10" name="email" required type="email"
                                v-bind:invalid="!!requestStore.errors?.email || (email.length > 0 && !validEmail)"
                                v-bind:label="__('Email')"
                                v-bind:placeholder="__('placeholders.email')"
                                v-on:keyup="validateEmail"
                                v-model="email"
                />
                <span class="px-1 text-error text-xs" v-if="email.length > 0 && !validEmail">
                    {{ __('The format of the email address is incorrect.') }}
                </span>

                <c-control-text class="mt-10" name="password" placeholder="**********" required type="password"
                                v-bind:invalid="!!requestStore.errors?.password || (password.length > 0 && password.length < 8)"
                                v-bind:label="__('Password')"
                                v-model="password"
                />
                <span class="px-1 text-error text-xs" v-if="password.length > 0 && password.length < 8">
                    {{ __('Password must be at least :length characters long.', { 'length' : 8 }) }}
                </span>

                <div class="flex items-center mt-10 text-sm">
                    <c-control-toggle class="h-[30px] mr-4" v-model="accepted"
                    />
                    <span v-html="__('acceptTerms')" />
                </div>

                <c-button class="bg-highlight block mt-10 text-white w-full"
                          v-bind:disabled="name.length === 0 || !validEmail || password.length < 8 || !accepted"
                          v-on:click="step = 2"
                >
                    {{ __('Next') }}
                    <c-icon class="h-4 ml-2 stroke-white w-4" icon="arrow-right" />
                </c-button>
            </div>

            <div class="bg-white border border-slate-200 max-w-[400px] mx-auto p-5 relative rounded shadow-sm w-full"
                v-show="step === 2"
            >
                <span class="cursor-pointer flex items-center mb-5 text-slate-400 text-sm"
                      v-on:click="step = 1"
                >
                    <c-icon class="h-4 stroke-slate-400 mr-2 w-4" icon="arrow-left" />
                    {{ __('Back') }}
                </span>

                <c-control-text class="mb-10" name="company.name" required type="text"
                                v-bind:invalid="!!requestStore.errors?.company?.name"
                                v-bind:label="__('Company name')"
                                v-bind:placeholder="__('placeholders.company_name')"
                                v-model="company.name"
                />

                <c-control-text class="mb-10" name="company.tax_id" required
                                v-bind:invalid="!!requestStore.errors?.company?.tax_id"
                                v-bind:label="__('Tax ID')"
                                v-bind:placeholder="__('placeholders.tax_id')"
                                v-model="company.tax_id"
                />

                <c-control-select class="mb-10"
                                  v-bind:invalid="!!requestStore.errors?.company?.country"
                                  v-bind:label="__('Country')"
                                  v-bind:name="'company.country'"
                                  v-bind:options="countries"
                                  v-model="company.country"
                />

                <c-control-text class="mb-10"
                                v-bind:invalid="!!requestStore.errors?.company?.postcode"
                                v-bind:label="__('Postcode')"
                                v-bind:name="'partner.postcode'"
                                v-bind:placeholder="__('placeholders.postcode')"
                                v-if="company.country === 'HU'"
                                v-model="company.postcode"
                />

                <c-control-text class="mb-10"
                                v-bind:invalid="!!requestStore.errors?.company?.county"
                                v-bind:label="__('County')"
                                v-bind:name="'partner.county'"
                                v-bind:placeholder="__('placeholders.county')"
                                v-else
                                v-model="company.county"
                />

                <c-control-text class="mb-10"
                                v-bind:invalid="!!requestStore.errors?.company?.city"
                                v-bind:label="__('City')"
                                v-bind:name="'partner.city'"
                                v-bind:placeholder="__('placeholders.city')"
                                v-model="company.city"
                />

                <c-control-text class="mb-10"
                                v-bind:invalid="!!requestStore.errors?.company?.address_line_1"
                                v-bind:label="__('Address line 1')"
                                v-bind:name="'partner.address_line_1'"
                                v-bind:placeholder="__('Street name, number')"
                                v-model="company.address_line_1"
                />

                <c-button class="bg-highlight block text-white w-full" submit
                          v-bind:disabled="company.name.length === 0"
                          v-bind:invalid="!!requestStore.errors?.accepted"
                >
                    {{ __('Register') }}
                </c-button>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref, watch } from 'vue';
    import { useRoute } from 'vue-router';
    import {
        CButton,
        CControlSelect,
        CControlText,
        CControlToggle,
        CIcon,
    } from '@teamfurther/cinderblock';

    import { useGeoStore } from '../../stores/geo';
    import { useRequestStore }  from '../../stores/request';
    import { useSessionStore }  from '../../stores/session';

    const accepted = ref<boolean>(false);

    const company = ref<object>({
        address_line_1: '',
        city: '',
        country: 'HU', // @TODO make dependent on device language
        county: '',
        name: '',
        postcode: '',
        tax_id: '',
    });

    const countries = ref([]);
    const email = ref<string>('');
    const geoStore = useGeoStore();
    const name = ref<string>('');
    const password = ref<string>('');
    const requestStore = useRequestStore();
    const route = useRoute();
    const sessionStore = useSessionStore();
    const step = ref<number>(1);
    const validEmail = ref<boolean>(false);

    async function submit() {
        await sessionStore.registerUser(
            accepted.value,
            name.value,
            email.value,
            password.value,
            company.value,
            route.query.utm_campaign ?? null,
            route.query.utm_medium ?? null,
            route.query.utm_source ?? null
        );
    }

    function validateEmail() {
        validEmail.value = !!email.value.match(/\S+@\S+\.\S+/);
    }

    onMounted(async () => {
        if (sessionStore.user) {
            requestStore.redirect({ name: 'errors.403' });
        }

        countries.value = await geoStore.countries();
    });

    watch(() => requestStore.errors, () => {
        if (requestStore.errors && (requestStore.errors.name || requestStore.errors.email || requestStore.errors.password || requestStore.errors.accepted)) {
            step.value = 1;
        }
    });
</script>