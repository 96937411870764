<template>
    <c-modal class="max-w-[600px]"
             v-bind:open="open && !deletedLog"
             v-bind:title="__('Edit time logs')"
             v-on:closeModal="closeDestination"
    >
        <template v-slot:body>
            <div class="flex flex-col" v-if="worksheet">
                <div class="mb-1" v-for="assignee in timeLogsGroupedByAssignee">
                    <div class="mb-2">
                        <img class="h-6 inline mr-2 -mt-1 rounded w-6"
                             v-bind:src="assignee.assignee.avatar"
                             v-bind:alt="assignee.assignee.name"
                        />
                        <span class="text-sm">{{ assignee.assignee.name }}</span>
                    </div>
                    <div class="border-b border-dotted border-slate-200 flex items-center justify-between mb-1 pb-1"
                         v-for="log in assignee.items"
                    >
                        <span class="italic mb-px py-2 text-slate-400 text-xs">
                            <span class="relative"
                                  v-on:mouseover="tooltipTime = log.id + '_started'"
                                  v-on:mouseout="tooltipTime = null"
                            >
                                {{
                                    DateTime.fromISO(
                                        log.started_at,
                                        { locale : localizationStore.locale }
                                    ).toLocaleString(DateTime.TIME_24_SIMPLE)
                                }}
                                <c-tooltip class="ml-1" position="right"
                                           v-bind:active="tooltipTime === log.id + '_started'"
                                >
                                    {{
                                        DateTime.fromISO(
                                            log.started_at,
                                            { locale : localizationStore.locale }
                                        ).toFormat(localizationStore.dateFormat)
                                    }}
                                </c-tooltip>
                            </span>
                            -
                            <span class="relative"
                                  v-on:mouseover="tooltipTime = log.id + '_stopped'"
                                  v-on:mouseout="tooltipTime = null"
                            >
                                {{
                                    DateTime.fromISO(
                                        log.stopped_at,
                                        { locale : localizationStore.locale }
                                    ).toLocaleString(DateTime.TIME_24_SIMPLE)
                                }}
                                <c-tooltip class="ml-1" position="right"
                                           v-bind:active="tooltipTime === log.id + '_stopped'"
                                >
                                    {{
                                        DateTime.fromISO(
                                            log.stopped_at,
                                            { locale : localizationStore.locale }
                                        ).toFormat(localizationStore.dateFormat)
                                    }}
                                </c-tooltip>
                            </span>
                        </span>
                        <span class="font-bold"
                              v-if="!editedLog || editedLog !== log.id"
                        >
                            {{ log.time_for_humans }}
                            <c-icon class="cursor-pointer h-4 inline ml-2 stroke-highlight w-4" icon="edit-3"
                                    v-on:click="editedLog = log.id"
                            />
                            <c-icon class="cursor-pointer h-4 inline ml-2 stroke-error w-4" icon="trash-2"
                                    v-on:click="deletedLog = log.id"
                            />
                        </span>
                        <div class="flex items-center text-sm"
                             v-if="editedLog === log.id"
                        >
                            <c-control-number class="w-12"
                                              name="log.hours"
                                              v-bind:min="0"
                                              v-model="log.hours"
                                              v-on:keyup.enter="updateTimeLog(log)"
                            />h
                            <c-control-number class="ml-2 w-12"
                                              name="log.minutes"
                                              v-model="log.minutes"
                                              v-bind:max="59"
                                              v-bind:min="0"
                                              v-on:keyup.enter="updateTimeLog(log)"
                            />'
                            <c-icon class="cursor-pointer h-4 inline ml-5 stroke-slate-500 w-4" icon="x"
                                    v-on:click="log.hours = log.hours_old; log.minutes = log.minutes_old; editedLog = null"
                            />
                            <c-icon class="cursor-pointer h-4 inline ml-1 stroke-success w-4" icon="check"
                                    v-on:click="updateTimeLog(log)"
                            />
                        </div>
                    </div>
                </div>
                <div class="mb-5 pb-1 text-right text-sm"
                     v-if="!addingLog"
                >
                    <c-button class="bg-highlight text-white"
                              v-on:click="addingLog = true"
                    >
                        + {{ __('Add time entry') }}
                    </c-button>
                </div>
                <div class="flex items-center justify-between mb-5 text-sm"
                     v-if="addingLog"
                >
                    <c-control-autocomplete class="grow mr-10"
                                            avatar-field="avatar" label-field="name" name="ticket.assignee"
                                            v-bind:invalid="assigneeHasError"
                                            v-bind:placeholder="__('Search by employee name')"
                                            v-bind:source-get="employeeGet"
                                            v-bind:source-search="employeeSearch"
                                            v-model="newLog.assignee"
                                            v-on:change="assigneeHasError = false"
                    />

                    <div class="flex items-center shrink-0">
                        <c-control-number class="w-12"
                                          name="newLog.hours"
                                          v-bind:min="0"
                                          v-model="newLog.hours"
                                          v-on:keyup.enter="addTimeLog"
                        />h
                        <c-control-number class="ml-2 w-12"
                                          name="newLog.minutes"
                                          v-model="newLog.minutes"
                                          v-bind:max="59"
                                          v-bind:min="0"
                                          v-on:keyup.enter="addTimeLog"
                        />'
                        <c-icon class="cursor-pointer h-4 inline ml-5 stroke-slate-500 w-4" icon="x"
                                v-on:click="addingLog = false; newLog.assignee = null; newLog.minutes = 0; newLog.hours = 0;"
                        />
                        <c-icon class="cursor-pointer h-4 inline ml-1 stroke-success w-4" icon="check"
                                v-on:click="addTimeLog"
                        />
                    </div>
                </div>

                <strong class="block text-right text-sm">{{ __('Total') }}</strong>
                <span class="block font-black text-2xl text-right">{{ worksheet.time_for_humans }}</span>
            </div>
        </template>
        <template v-slot:footer>
            <div class="bg-white flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" dusk="cancel-button" v-on:click="closeDestination">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-success text-white" dusk="save-button" v-on:click="closeDestination">
                    {{ __('Close') }}
                </c-button>
            </div>
        </template>
    </c-modal>

    <c-modal class="md:w-[450px]" v-bind:open="deletedLog !== null" v-bind:title="__('Delete time entry')" v-on:closeModal="deletedLog = null">
        <template v-slot:body>
            <div class="py-5 text-center text-sm">
                {{ __('Are you sure you want to delete this time entry?') }}
            </div>
        </template>
        <template v-slot:footer>
            <div class="flex justify-end w-full">
                <c-button class="bg-slate-100 mr-2 text-slate-800" v-on:click="deletedLog = null">
                    {{ __('Cancel') }}
                </c-button>
                <c-button class="bg-error text-white" dusk="delete-time-log-modal-button" v-on:click="del">
                    {{ __('Delete') }}
                </c-button>
            </div>
        </template>
    </c-modal>
</template>

<script setup lang="ts">
    import { computed, isRef, ref, onMounted } from 'vue';
    import { useRoute, useRouter } from 'vue-router'
    import { DateTime } from 'luxon';
    import {
        CButton,
        CControlAutocomplete,
        CControlNumber,
        CIcon,
        CModal,
        CTooltip,
    } from '@teamfurther/cinderblock';

    import EmployeeRepository from '../../../repositories/EmployeeRepository';
    import WorksheetRepository from '../../../repositories/WorksheetRepository';
    import WorksheetTimeRepository from '../../../repositories/WorksheetTimeRepository';
    import { useLocalizationStore } from '../../../stores/localization';
    import { useRequestStore } from '../../../stores/request';

    const addingLog = ref<boolean>(false);
    const assigneeHasError = ref<boolean>(false);
    const deletedLog = ref(null);
    const editedLog = ref<string>();
    const employeeRepository = EmployeeRepository.getInstance();
    const localizationStore = useLocalizationStore();
    const open = ref<boolean>(true);
    const newLog = ref({
        assignee: null,
        hours: 0,
        minutes: 0,
    });
    const requestStore = useRequestStore();
    const route = useRoute();
    const router = useRouter();

    const timeLogsGroupedByAssignee = computed(() => {
        if (!worksheet.value) {
            return [];
        }

        let logs = {};

        for (let log of worksheet.value.time_logs) {
            if (!logs[log.assignee.id]) {
                logs[log.assignee.id] = {
                    assignee: log.assignee,
                    items: [],
                };
            }

            log.duration = Math.floor(log.time / 60);
            log.hours = log.hours_old = Math.floor(log.duration / 60);
            log.minutes = log.minutes_old = log.duration % 60;

            logs[log.assignee.id].items.push(log);
        }

        return logs;
    });

    const tooltipTime = ref<string | null>(null);
    const worksheet = ref<object>();
    const worksheetId = route.params.worksheet;
    const worksheetRepository = WorksheetRepository.getInstance();
    const worksheetTimeRepository = WorksheetTimeRepository.getInstance();

    function addTimeLog() {
        if (!newLog.value.assignee) {
            assigneeHasError.value = true;

            return;
        }

        worksheetTimeRepository.store({
            assignee_id: newLog.value.assignee.id,
            worksheet_id: worksheet.value.id,
            time: (parseInt(newLog.value.hours) * 60 + parseInt(newLog.value.minutes)) * 60,
        });

        addingLog.value = false;
        newLog.value = {
            assignee: null,
            hours: 0,
            minutes: 0,
        };

        getWorksheet();
    }

    function closeDestination() {
        open.value = false;

        if (!window.history.state.back) {
            router.push({ name : 'tickets' });
        } else {
            router.go(-1);
        }
    }

    async function del() {
        await worksheetTimeRepository.destroy(deletedLog.value);

        await getWorksheet();

        deletedLog.value = null;
    }

    async function employeeGet(employee) {
        if (isRef(employee) && employee.value) {
            return await employeeRepository.show(employee.value.id);
        } else if (employee && employee.id) {
            return await employeeRepository.show(employee.id);
        } else {
            return null;
        }
    }

    async function employeeSearch(val) {
        return await employeeRepository.index({
            filters : {
                search: val
            }
        });
    }

    async function getWorksheet() {
        worksheet.value = await worksheetRepository.show(worksheetId, {
            include: [
                'time_logs.assignee',
            ]
        });
    }

    async function updateTimeLog(log) {
        await worksheetTimeRepository.update(log.id, {
            time: (parseInt(log.hours) * 60 + parseInt(log.minutes)) * 60,
        });

        editedLog.value = null;

        await getWorksheet();
    }

    onMounted(async () => {
        await getWorksheet();
    });
</script>
