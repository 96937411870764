import Repository from './Repository';

export default class WorksheetTimeRepository extends Repository {
    protected basePath = 'worksheets/time';

    protected messages = {
        destroy : this.__('Time entry successfully deleted.'),
        store : this.__('Time entry successfully created.'),
        update : this.__('Time entry successfully updated.'),
    }

    protected redirects = {
        store : null,
    }

    public import(data: object): Promise {
        console.error('Import method does not exist on WorksheetTimeRepository.');
    }

    public show(id: string, params: object = null): Promise {
        console.error('Show method does not exist on WorksheetTimeRepository.');
    }
}